import { arrow } from "@tightrope/newtab";

const Util = require("./util.js");
const Shortcuts = require("./Shortcuts.js");

const { TOP_DOMAIN } = Util;

$(document).ready(async function() {
    const searchElement = "search-text";
    if (searchElement && searchElement !== "") window._tr_ac_se = searchElement;
    const userGuid = await Util.getGuid();
    const speedTestiFrame = document.getElementById("speedGaugeContainer");
    Shortcuts.loadShortcuts("#favor");

    arrow("newtab");
    if (new URL(window.location.href).searchParams.get("tutorial") === "true") {
        window.scrollTo(0, 450);
        $(".firstrun").css("display", "block");
        $("body").css({ overflow: "hidden" });
        $(".step-a").click(function() {
            $(".firstrun").hide();
            $(".step-a").fadeOut();
            $(".step-b").show();
            $(".b-card").slideDown();
            $("body").css({ overflow: "scroll" });
        });

        $(".step-b").click(function() {
            $(".b-card").slideUp();
            $(".firstrun").hide();
        });
    }

    if (new URL(window.location.href).searchParams.get("start") === "true") {
        startSpeedTest();
    }

    $("#search-button").click(function() {
        $("#search-form").submit();
    });

    $("#speed-test-button").click(function() {
        startSpeedTest();
    });

    $("#speed-test-x").click(function() {
        $("#speedGaugeWrapper").hide();
        $("#speedGaugeWrapper").toggleClass("expand");
        // $("#speedGaugeContainer").hide();
        $("#lb").css("display", "none");
        $(".x").toggleClass("xOut");
        // sending stop message to iframe
        speedTestiFrame.contentWindow.postMessage("stop", "*");
    });

    $("#search-form").submit(e => {
        e.preventDefault();
        window.location = `https://services.${TOP_DOMAIN}/crx/search.php?action=nt&guid=${userGuid}&k=${$(
            "#search-text"
        ).val()}`;
    });

    function startSpeedTest() {
        speedTestiFrame.src = `https://misc-services.${TOP_DOMAIN}/speedtest/`;

        $("#speedGaugeWrapper").show();
        $("#speedGaugeWrapper").toggleClass("expand");
        $("#speedGaugeContainer").show();
        $("#lb").css("display", "block");
        $(".x").toggleClass("xOut");
        // sending start message to iframe

        speedTestiFrame.onload = function() {
            speedTestiFrame.contentWindow.postMessage("start", "*");
        };
    }

    function updateSpeedList() {
        const speedList = Util.getSetting("speedList")
            ? JSON.parse(Util.getSetting("speedList"))
            : [];

        if (speedList.length < 2) {
            $("#fastest_dlspd").hide();
        } else {
            $("#speed_history").css("display", "block");
            const minItem = speedList.reduce(
                (min, p) =>
                    parseFloat(p.download) < parseFloat(min.download) ? p : min,
                speedList[0]
            );
            const maxItem = speedList.reduce(
                (max, p) =>
                    parseFloat(p.download) > parseFloat(max.download) ? p : max,
                speedList[0]
            );
            if (maxItem && minItem) {
                $("#fastest_dlspd ul li").remove();
                $("#slowest_dlspd ul li").remove();
                $("#fastest_dlspd ul").append(`<li>
                    <div class="speedlistItem">
                        <span class="datetime datetimeItem">
                            <span class="history_date">Fastest</span>
                        </span>
        
                        <span class="spd downloadspeed downloadspeedItem">
                            <span class="tooltip"
                                ><img
                                    src="assets/images/download.png"
                                />
                                <span class="tooltiptext"
                                    >Download Speed</span
                                > </span
                            ><span>${maxItem.download}Mbps</span>
                        </span>
                        <span class="spd uploadspeed uploadspeedItem">
                            <span class="tooltip"
                                ><img src="assets/images/upload.png" />
                                <span class="tooltiptext"
                                    >Upload Speed</span
                                ></span
                            >
                            <span>${maxItem.upload}Mbps</span>
                        </span>
                    </div>
                </li>`);
                $("#slowest_dlspd ul").append(`<li>
                    <div class="speedlistItem">
                        <span class="datetime datetimeItem">
                            <span class="history_date">Slowest</span>
                        </span>
        
                        <span class="spd downloadspeed downloadspeedItem">
                            <span class="tooltip"
                                ><img
                                    src="assets/images/download.png"
                                />
                                <span class="tooltiptext"
                                    >Download Speed</span
                                > </span
                            ><span>${minItem.download}Mbps</span>
                        </span>
                        <span class="spd uploadspeed uploadspeedItem">
                            <span class="tooltip"
                                ><img src="assets/images/upload.png" />
                                <span class="tooltiptext"
                                    >Upload Speed</span
                                ></span
                            >
                            <span>${minItem.upload}Mbps</span>
                        </span>
                    </div>
                </li>`);
                $("#fastest_dlspd").show();
                $("#slowest_dlspd").show();
            }
        }
        $("#speedList li").remove();
        speedList.reverse().forEach(item => {
            const datetime = new Date(item.date);
            const date = `${`0${datetime.getMonth() + 1}`.slice(
                -2
            )}/${`0${datetime.getDate()}`.slice(-2)}`;
            const time = `${`0${datetime.getHours()}`.slice(
                -2
            )}:${`0${datetime.getMinutes()}`.slice(-2)}`;
            const template = `<li>
                <div class="speedlistItem">
                    <span class="datetime datetimeItem">
                        <span class="history_date">${date}</span>
                        <span class="history_time">${time}</span>
                    </span>
        
                    <span class="spd downloadspeed downloadspeedItem">
                        <span class="tooltip"
                            ><img src="assets/images/download.png" />
                            <span class="tooltiptext"
                                >Download Speed</span
                            > </span
                        ><span>${item.download}Mbps</span>
                    </span>
                    <span class="spd uploadspeed uploadspeedItem">
                        <span class="tooltip"
                            ><img src="assets/images/upload.png" />
                            <span class="tooltiptext"
                                >Upload Speed</span
                            ></span
                        >
                        <span>${item.upload}Mbps</span>
                    </span>
                <div>
            </li>`;
            $("#speedList").append(template);
        });
    }
    const receiveMessage = function(e) {
        if (e.data && e.data.msg == "msgSpeed") {
            const { res } = e.data;
            res.date = Date.now();
            const speedList = Util.getSetting("speedList")
                ? JSON.parse(Util.getSetting("speedList"))
                : [];
            if (speedList.length >= 5) {
                speedList.shift();
            }
            speedList.push(res);
            Util.setSetting("speedList", JSON.stringify(speedList));
            updateSpeedList();
        }
    };
    window.addEventListener("message", receiveMessage, false);
    updateSpeedList();
});
